<template>
  <div :class="`shift-works ${getCurrentLangs.lang}`" data-view>
    <Header/>
    <scrollAnalytics></scrollAnalytics>

    <section class="top">
      <div class="top__head-wrapper">
        <div class="top__head" v-html="$t('shiftWork.head')"></div>
        <router-link to="/cases" class="top__back-btn cursor-hover">
          <div class="top__arrow">
            <svg viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.5388 19.3223L14.8514 30.01L25.5484 40.7071" stroke="black" stroke-linecap="round"/>
              <path d="M15.2988 29.973L38.2398 29.9732" stroke="black" stroke-linecap="round"/>
            </svg>
          </div>
          {{ $t("back-btn") }}
        </router-link>
      </div>
      <h1 class="top__title animation iosTitle type1">
        <span class="text-wrapper" v-for="(key, value) in $t('shiftWork.title')" :key="value">
          <span class="letters" v-html="key"></span>
        </span>
      </h1>
    </section>

    <section class="banner">
      <picture>
        <source srcset="@/assets/img/cases/shiftWork/banner-mob.jpg" media="(max-width: 425px)"/>
        <source srcset="@/assets/img/cases/shiftWork/banner-tablet.jpg" media="(max-width: 768px)"/>
        <img v-parallax.modifier.absY="0.07" src="@/assets/img/cases/shiftWork/banner.jpg" alt=""/>
      </picture>
      <div class="banner__text">
        <div class="banner__logo">
          <img src="../../assets/img/cases/shiftWork/teva-logo.png"/>
        </div>
        <div class="banner__text-col">
          <p class="banner__text-title" v-html="$t('shiftWork.banner.title')"></p>
        </div>
      </div>
    </section>

    <section class="section-text">
      <div class="text-row">
        <div class="text-row__left">
          <p class="text-row__left-text" v-html="$t('shiftWork.overview.left-text')"></p>
        </div>
        <div class="text-row__right">
          <p class="text-row__right-title" v-html="$t('shiftWork.overview.right-title')"></p>
          <p class="text-row__right-text" v-html="$t('shiftWork.overview.right-text')"></p>
          <p class="text-row__right-sub-title" v-html="$t('shiftWork.overview.right-sub-title')"></p>
          <ul class="text-row__right-list">
            <li class="text-row__right-list-item" v-html="$t('shiftWork.overview.right-list[0]')"></li>
            <li class="text-row__right-list-item" v-html="$t('shiftWork.overview.right-list[1]')"></li>
            <li class="text-row__right-list-item" v-html="$t('shiftWork.overview.right-list[2]')"></li>
            <li class="text-row__right-list-item" v-html="$t('shiftWork.overview.right-list[3]')"></li>
            <li class="text-row__right-list-item" v-html="$t('shiftWork.overview.right-list[4]')"></li>
          </ul>
          <p class="text-row__right-text" v-html="$t('shiftWork.overview.right-text2')"></p>
        </div>
      </div>
      <div class="text-with-img">
        <div class="text-with-img__text">
          <div class="text-row__left">
            <p class="text-row__left-text" v-html="$t('shiftWork.challenge.left-text')"></p>
          </div>
          <div class="text-row__right">
            <p class="text-row__right-title" v-html="$t('shiftWork.challenge.right-title')"></p>
            <p class="text-row__right-text" v-html="$t('shiftWork.challenge.right-text')"></p>
            <ol class="text-row__right-list">
              <li class="text-row__right-list-item" v-html="$t('shiftWork.challenge.right-list[0]')"></li>
              <li class="text-row__right-list-item" v-html="$t('shiftWork.challenge.right-list[1]')"></li>
            </ol>
            <p class="text-row__right-note" v-html="$t('shiftWork.challenge.right-note')"></p>
          </div>
        </div>
        <div class="text-with-img__img">
          <picture v-if="getCurrentLangs.lang === 'en'">
            <source media="(max-width:950px)" srcset="../../assets/img/cases/shiftWork/challenge-tablet.jpg"/>
            <img src="../../assets/img/cases/shiftWork/challenge.jpg"/>
          </picture>
          <picture v-else>
            <source media="(max-width:950px)" srcset="../../assets/img/cases/shiftWork/challenge-tablet-ua.jpg"/>
            <img src="../../assets/img/cases/shiftWork/challenge-ua.jpg"/>
          </picture>
        </div>
      </div>
      <div class="text-row">
        <div class="text-row__left">
          <p class="text-row__left-text" v-html="$t('shiftWork.approach.left-text')"></p>
        </div>
        <div class="text-row__right">
          <p class="text-row__right-title" v-html="$t('shiftWork.approach.right-title')"></p>
          <p class="text-row__right-text" v-html="$t('shiftWork.approach.right-text')"></p>
        </div>
      </div>
      <div class="text-row">
        <div class="text-row__left">
          <p class="text-row__left-text" v-html="$t('shiftWork.execution.left-text')"></p>
        </div>
        <div class="text-row__right">
          <p class="text-row__right-text" v-html="$t('shiftWork.execution.right-text')"></p>
        </div>
      </div>
    </section>

    <section class="banner-facebook">
      <picture>
        <source srcset="@/assets/img/cases/shiftWork/banner-facebook-mob.jpg" media="(max-width: 425px)"/>
        <source srcset="@/assets/img/cases/shiftWork/banner-facebook-tablet.jpg" media="(max-width: 768px)"/>
        <img v-parallax.modifier.absY="0.05" src="@/assets/img/cases/shiftWork/banner-facebook.jpg" alt=""/>
      </picture>
      <div class="banner-facebook__card">
        <img v-if="getCurrentLangs.lang === 'en'" src="@/assets/img/cases/shiftWork/banner-facebook-card.gif" alt=""/>
        <img v-else src="@/assets/img/cases/shiftWork/banner-facebook-card-ua.gif" alt=""/>
      </div>
    </section>

    <section class="creative-stories">
      <div class="section-text">
        <div class="text-row">
          <div class="text-row__left">
            <p class="text-row__left-text" v-html="$t('shiftWork.creativeStories.left-text')"></p>
          </div>
          <div class="text-row__right">
            <p class="text-row__right-title" v-html="$t('shiftWork.creativeStories.right-title')"></p>
            <p class="text-row__right-text" v-html="$t('shiftWork.creativeStories.right-text')"></p>
          </div>
        </div>
      </div>
      <div class="creative-stories-img">

        <picture >
          <img src="@/assets/img/cases/shiftWork/idea.jpg" alt=""/>
        </picture>


        <div class="creative-stories-img__cards">
          <img v-if="getCurrentLangs.lang==='en'" src="../../assets/img/cases/shiftWork/idea-card-1.png" alt="" class="creative-stories-img__cards-item-1" v-parallax.modifier.absY="-0.002"/>
          <img v-else src="../../assets/img/cases/shiftWork/idea-card-1-ua.png" alt="" class="creative-stories-img__cards-item-1" v-parallax.modifier.absY="-0.002"/>
          <img v-if="getCurrentLangs.lang==='en'" src="../../assets/img/cases/shiftWork/idea-card-2.png" alt="" class="creative-stories-img__cards-item-2" v-parallax.modifier.absY="0.002"/>
          <img v-else src="../../assets/img/cases/shiftWork/idea-card-2-ua.png" alt="" class="creative-stories-img__cards-item-2" v-parallax.modifier.absY="0.002"/>
          <img v-if="getCurrentLangs.lang==='en'" src="../../assets/img/cases/shiftWork/idea-card-3.png" alt="" class="creative-stories-img__cards-item-3" v-parallax.modifier.absY="-0.003"/>
          <img v-else src="../../assets/img/cases/shiftWork/idea-card-3-ua.png" alt="" class="creative-stories-img__cards-item-3" v-parallax.modifier.absY="-0.003"/>
        </div>
      </div>
      <img src="../../assets/img/cases/shiftWork/idea-circular-text.png" alt="" class="idea-circular-text"/>
    </section>

    <div class="video">
      <swiper ref="vitrSlider" :options="swiperOptions" class="video__slider swiper-container">
        <swiper-slide v-for="(val, index) in 5" :key="index" class="video__slider--slide">
          <div class="video__video-wrapper cursor-hover">
            <div class="video__play-btn cursor-hover" @click="playVideo(val)" v-bind:class="[{ active: !videoTrigger }]"></div>
            <video v-if="getCurrentLangs.lang === 'en'" @click="playVideo(val)" :ref="`video-${val}`" :poster="require(`../../assets/img/cases/shiftWork/${val}.jpg`)">
              <source :src="require(`../../assets/video/shiftWork/${val}-en.mp4`)" type="video/mp4"/>
            </video>
            <video v-else @click="playVideo(val)" :ref="`video-${val}`" :poster="require(`../../assets/img/cases/shiftWork/${val}.jpg`)">
              <source :src="require(`../../assets/video/shiftWork/${val}-ua.mp4`)" type="video/mp4"/>
            </video>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="pagination__wrapper">
        <button class="left button-prev cursor-hover">
          <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.2539 19.7256L15.5665 30.4133L26.2635 41.1104" stroke="" stroke-linecap="round"/>
            <path d="M16.016 30.3763L38.957 30.3765" stroke="" stroke-linecap="round"/>
          </svg>
        </button>
        <button class="right button-next cursor-hover">
          <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.7461 19.7256L42.4335 30.4133L31.7365 41.1104" stroke="" stroke-linecap="round"/>
            <path d="M41.984 30.3763L19.043 30.3765" stroke="" stroke-linecap="round"/>
          </svg>
        </button>
      </div>
    </div>

    <div class="photo-bg">
      <picture>
        <source srcset="@/assets/img/cases/shiftWork/slider-mob.png" media="(max-width: 425px)"/>
        <source srcset="@/assets/img/cases/shiftWork/slider-tablet.png" media="(max-width: 768px)"/>
        <img src="@/assets/img/cases/shiftWork/slider.png" v-parallax.modifier.abs="0.05" alt=""/>
      </picture>
    </div>
`
    <section class="section-text">
      <div class="text-row">
        <div class="text-row__left">
          <p class="text-row__left-text" v-html="$t('shiftWork.webSite.left-text')"></p>
        </div>
        <div class="text-row__right">
          <p class="text-row__right-title" v-html="$t('shiftWork.webSite.right-title')"></p>
          <ul class="text-row__list-icon">
            <li class="text-row__right-text">
              <img src="@/assets/img/cases/shiftWork/eye.svg" class="text-row__icon" alt=""/>
              <span v-html="$t('shiftWork.webSite.right-list[0]')"></span>
            </li>
            <li class="text-row__right-text">
              <img src="@/assets/img/cases/shiftWork/eye-close.svg" class="text-row__icon" alt=""/>
              <span v-html="$t('shiftWork.webSite.right-list[1]')"></span>
            </li>
            <li class="text-row__right-text">
              <img src="@/assets/img/cases/shiftWork/eye.svg" class="text-row__icon" alt=""/>
              <span v-html="$t('shiftWork.webSite.right-list[2]')"></span>
            </li>
            <li class="text-row__right-text">
              <img src="@/assets/img/cases/shiftWork/eye-close.svg" class="text-row__icon" alt=""/>
              <span v-html="$t('shiftWork.webSite.right-list[3]')"></span>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <div class="photo-bg photo-bg_gray">
      <picture v-if="getCurrentLangs.lang==='en'">
        <source srcset="@/assets/img/cases/shiftWork/landing-mob.png" media="(max-width: 425px)"/>
        <source srcset="@/assets/img/cases/shiftWork/landing-tablet.png" media="(max-width: 768px)"/>
        <img src="@/assets/img/cases/shiftWork/landing.png" v-parallax.modifier.abs="0.05" alt=""/>
      </picture>
      <picture v-else>
        <source srcset="@/assets/img/cases/shiftWork/landing-ua.png" media="(max-width: 425px)"/>
        <img src="@/assets/img/cases/shiftWork/landing-ua.png" v-parallax.modifier.abs="0.05" alt=""/>
      </picture>
    </div>

    <section class="section-text">
      <div class="text-row">
        <div class="text-row__left">
          <p class="text-row__left-text" v-html="$t('shiftWork.interactive.left-text')"></p>
        </div>
        <div class="text-row__right">
          <p class="text-row__right-text" v-html="$t('shiftWork.interactive.right-text')"></p>
        </div>
      </div>
    </section>

    <div class="photo-bg photo-bg_gray photo-bg_full">
      <picture>
        <source srcset="@/assets/img/cases/shiftWork/web-mob.jpg" media="(max-width: 425px)"/>
        <source srcset="@/assets/img/cases/shiftWork/web-tablet.jpg" media="(max-width: 768px)"/>
        <img src="@/assets/img/cases/shiftWork/web.jpg" v-parallax.modifier.abs="0.05" alt=""/>
      </picture>
    </div>

    <section class="section-text result">
      <div class="text-row">
        <div class="text-row__left">
          <p class="text-row__left-text" v-html="$t('shiftWork.results.left-text')"></p>
        </div>
        <div class="text-row__right">
          <p class="text-row__right-text" v-html="$t('shiftWork.results.right-text')"></p>
          <p class="text-row__right-text" v-html="$t('shiftWork.results.right-text2')"></p>
        </div>
      </div>
      <div class="result-table">
        <ul class="table">
          <li class="table__row table__row-title">
            <div class="col col_1"></div>
            <div class="col col_2" v-html="$t('shiftWork.results.table-title[0]')"></div>
            <div class="col col_3" v-html="$t('shiftWork.results.table-title[1]')"></div>
          </li>
          <li v-for="(items, value) in $t('shiftWork.results.table')" :key="value" class="table__row">
            <div class="col" v-for="(item, val) in items" :key="val">
              <span v-html="item"></span>
            </div>
          </li>
        </ul>
        <div class="table-devide"></div>
        <ul class="table table-bottom">
          <li v-for="(items, value) in $t('shiftWork.results.table2')" :key="value" class="table__row">
            <div class="col" v-for="(item, val) in items" :key="val">
              <span v-html="item"></span>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section class="banner-facebook phone-stories">
      <picture>
        <source srcset="@/assets/img/cases/shiftWork/phone-bg-mob.jpg" media="(max-width: 425px)"/>
        <source srcset="@/assets/img/cases/shiftWork/phone-bg-tablet.jpg" media="(max-width: 768px)"/>
        <img v-parallax.modifier.absY="0.05" src="@/assets/img/cases/shiftWork/phone-bg.jpg" alt=""/>
      </picture>
      <div class="banner-facebook__card">
        <img v-if="getCurrentLangs.lang==='en'" src="@/assets/img/cases/shiftWork/phone-stories.gif" alt=""/>
        <img v-else src="@/assets/img/cases/shiftWork/phone-stories-ua.gif" alt=""/>
      </div>
    </section>

    <similar :dataSlider="similarData"></similar>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import similar from "@/components/similar.vue";
import Footer from "@/components/NewFooter.vue";
import anime from "animejs";
import {mapGetters, mapMutations} from "vuex";
// import getElementVisible from '@/helper';

export default {
  name: "ShiftWork",
  components: {
    Header,
    Footer,
    similar,
  },

  data() {
    return {
      videoTrigger: false,
      similarData: {
        title: "similar-title-type1",
        prevLink: "/cases/butt",
        nextLink: "/cases/vacciland",
        linkShow: true,
        slider: ["slider-vacciland", , "slider-happy-liver", "slider-no-spa", "slider-allegra"],
      },
      swiperOptions: {
        slidesPerView: "auto",
        spaceBetween: 30,

        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".button-next",
          prevEl: ".button-prev",
        },
      },
      currentPosesSlide: 1,
      currentSlide: 0,
    };
  },

  mounted() {
    this.setCurrentPage("cases");

    setTimeout(() => {
      const whySection = document.querySelector(".top");
      const textWrapperAll = whySection.querySelectorAll(".text-wrapper .letters");
      textWrapperAll.forEach((e, index) => {
        const textWrapper = e;

        if (textWrapper.textContent.includes("®")) {
          textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>").replace("®", "<sup>®</sup>");
        } else {
          textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
        }

        anime.timeline({loop: false}).add({
          targets: ".text-wrapper .letter",
          translateY: ["1.2em", 0],
          translateZ: 0,
          duration: 750,
          delay: (el, i) => 50 * i,
        });
      });
    }, 20);
  },

  methods: {
    ...mapMutations(["setCurrentPage"]),

    playVideo(num) {
      const mediaVideo = this.$refs[`video-${num}`];
      if (mediaVideo[0].paused) {
        mediaVideo[0].play();
        this.videoTrigger = true;
        gtag('event', 'scroll', {'event_category': 'play video', 'event_label': this.$route.path})

      } else {
        mediaVideo[0].pause();
        this.videoTrigger = false;
      }
    },
  },

  computed: {
    ...mapGetters(["getCurrentLangs"]),
  },
};
</script>

<style scoped lang="scss">
.shift-works {
  margin-top: 74px;

  @media screen and (max-width: 768px) {
    margin-top: 68px;
  }
  @media screen and (max-width: 660px) {
    margin-top: 56px;
  }

  &.true {
    opacity: 0;
  }

  &.ua {
    .top {
      &__title {
      }
    }
  }

  .parallax {
    overflow: hidden;
  }

  .top {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 88px 45px 61px 45px;

    @media screen and (max-width: 768px) {
      padding: 36px 45px 40px 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 10px 20px 24px 20px;
    }

    &__head-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 32px;

      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
      @media screen and(max-width: 500px) {
        flex-direction: column-reverse;
      }

      @media screen and (max-width: 425px) {
        margin-bottom: 10px;
      }
    }

    &__back-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;

      letter-spacing: 0.04em;
      min-width: 158px;

      color: var(--text-primary-color-case);

      display: flex;
      align-items: center;

      transition: all 0.2s ease;

      @media screen and (min-width: 1023px) {
        &:hover {
          svg {
            path {
              stroke: #29f6d9;
            }
          }
        }
      }

      &:active {
        svg {
          path {
            stroke: #29f6d9;
          }
        }
      }

      @media screen and(max-width: 768px) {
        margin-top: 0;
      }
      @media screen and(max-width: 500px) {
        margin: 0 0 0 auto;
      }
    }

    &__arrow {
      width: 40px;
      margin-right: 10px;

      svg {
        width: 100%;
        height: auto;

        path {
          transition: all 0.2s ease;
          stroke: var(--text-primary-color-case);
        }

        display: block;
      }
    }

    &__head {
      line-height: 24px;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--case-allegra-color-top);
      text-align: left;
      font-weight: 500;
      font-size: 16px;
      padding: 0 0 0 0.07em;
      margin: 0 auto 0 0;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__title {
      font-family: "TimesNewRoman-I";
      font-size: 108px;
      text-align: left;
      font-weight: 400;

      font-style: italic;

      color: var(--text-primary-color-case);
      width: 100%;
      line-height: 0.6em;
      letter-spacing: 1.1px;

      position: relative;

      .text-wrapper {
        position: relative;
        display: inline-block;
        overflow: hidden;
        padding: 0.1em 0.07em 0.1em 0.07em;
      }

      ::v-deep .letter {
        display: inline-block;
        line-height: 0.9em;
      }

      @media screen and (max-width: 768px) {
        font-size: 66px;
      }

      @media screen and (max-width: 590px) {
        font-size: 44px;
      }
    }
  }

  .banner {
    width: 100%;
    overflow: hidden;
    position: relative;

    @media screen and (min-width: 1024px) {
      padding-top: 33.25%;
      picture {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }

    &__text {
      max-width: calc(1108px + 90px);
      width: 100%;
      margin: 0 auto;
      padding: 53px 45px 76px 45px;
      display: flex;
      align-items: center;
      text-align: left;
      position: relative;
      z-index: 2;
      @media screen and (max-width: 1023px) {
        display: none;
      }
    }

    &__text-title {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.02em;
      color: #d6d6e0;
      flex: 1;
      padding-left: 23px;
    }

    &__logo {
      width: 144px;

      img {
        display: block;
        max-width: 100%;
      }
    }
  }

  .text-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 61px;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
      margin-bottom: 45px;
    }

    &__left {
      margin-right: 40px;
      min-width: 157px;

      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    &__left-text {
      font-size: 16px;
      line-height: 1.6em;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--text-primary-color-case);
      font-weight: 500;
      text-align: left;

      @media screen and (max-width: 425px) {
        font-size: 14px;
      }
    }

    &__right {
      margin-left: 40px;

      max-width: 66%;
      width: 100%;

      @media screen and (max-width: 600px) {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &__right-title {
      text-align: left;
      font-size: 24px;
      line-height: 36px;
      color: var(--text-primary-color-case);
      font-weight: 500;
      margin-bottom: 18px;

      @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
      }
      @media screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    &__right-text {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: var(--text-primary-color-case);
      margin-bottom: 18px;

      @media screen and (max-width: 425px) {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__right-sub-title {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: var(--text-primary-color-case);
      margin-bottom: 10px;

      @media screen and (max-width: 425px) {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__right-list {
      text-align: left;
      margin-bottom: 8px;
    }

    &__right-list-item {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: var(--text-primary-color-case);
      padding-left: 26px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 10px;
        left: 12px;
        width: 2px;
        height: 2px;
        background: var(--text-primary-color-case);
        border-radius: 50%;
      }
    }

    &__right-note {
      text-align: left;
      font-weight: 300;
      font-size: 10px;
      line-height: 140%;
      color: var(--text-primary-color-case);

      &::v-deep a {
        color: #ed475d;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__list-icon {
      img {
        width: 22px;
        position: absolute;
        left: 0;
        top: 9px;
      }

      .text-row__right-text {
        margin-bottom: 8px;
        position: relative;
        padding-left: 45px;
      }
    }
  }

  .text-with-img {
    display: flex;
    align-items: center;
    margin: 109px 0 108px;
    @media screen and (max-width: 950px) {
      flex-direction: column-reverse;
      margin: 64px 0 65px;
    }
    @media screen and (max-width: 600px) {
      margin: 47px 0 43px;
    }

    &__text {
      width: 50%;

      @media screen and (max-width: 950px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      @media screen and (max-width: 450px) {
        display: block;
      }
    }

    &__img {
      width: 50%;
      @media screen and (max-width: 950px) {
        width: 100%;
        margin-bottom: 65px;
      }
      @media screen and (max-width: 600px) {
        margin-bottom: 45px;
      }

      img {
        max-width: 100%;
        display: block;
        margin: 0 auto;
        border-radius: 15px;
        @media screen and (max-width: 600px) {
          border-radius: 7px;
        }
      }
    }

    .text-row__left {
    }

    .text-row__right {
      @media screen and (min-width: 951px) {
        margin: 0;
        max-width: 100%;
      }
    }

    .text-row__left-text {
      margin-bottom: 60px;
      @media screen and (max-width: 600px) {
        margin-bottom: 20px;
      }
    }

    .text-row__right-text {
      margin-bottom: 7px;
    }

    .text-row__right-list {
      margin: 0px 0 47px;
      padding: 0 0 0 23px;
      @media screen and (max-width: 950px) {
        margin: 0 0 34px;
      }
    }

    .text-row__right-list-item {
      padding-left: 0px;

      &::before {
        display: none;
      }
    }
  }

  .section-text {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 109px 45px 105px 45px;

    @media screen and (max-width: 768px) {
      padding: 54px 45px 65px 45px;
    }

    @media screen and (max-width: 600px) {
      padding: 47px 20px 48px 20px;
    }

    &__double-block {
    }

    &__video-wrapper {
      margin-top: 64px;
      position: relative;

      video {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &__play-btn {
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      &.active {
        display: block;

        &:before {
          opacity: 1;
        }
      }

      &:before {
        content: "Play";
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        opacity: 0;

        justify-content: center;
        align-items: center;
        background: #29f6d9;

        z-index: 2;

        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        margin: auto;

        color: #000000;

        transition: all 0.2s ease;

        font-family: "TimesNewRoman-I";
        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.04em;

        @media screen and (max-width: 425px) {
          width: 60px;
          height: 60px;
          font-size: 16px;
        }
      }
    }
  }

  .banner-facebook {
    width: 100%;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: auto;
      display: block;
    }

    &__card {
      border-radius: 22px;
      overflow: hidden;
      width: 21.25%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      img {
        display: block;
        width: 100%;
        border-radius: 22px;
      }

      @media screen and (max-width: 768px) {
        width: 53.3%;
      }
      @media screen and (max-width: 425px) {
        width: 69.5%;
        border-radius: 10px;
      }
    }
  }

  .creative-stories {
    padding: 0px 0px 105px 0px;
    @media screen and (max-width: 950px) {
      padding-bottom: 87px;
    }
    @media screen and (max-width: 600px) {
      padding-bottom: 50px;
    }

    .section-text {
      padding-bottom: 109px;
      @media screen and (max-width: 950px) {
        padding-bottom: 54px;
      }
      @media screen and (max-width: 700px) {
        padding-bottom: 47px;
      }
    }
  }

  .creative-stories-img {
    width: 100%;
    position: relative;

    picture img {
      width: 100%;

      height: auto;
      display: block;
      @media screen and (max-width: 1600px) {
        min-width: 1600px;
        margin: 0 calc((100vw - 1600px) / 2);
      }
      @media screen and (max-width: 950px) {
        min-width: 1430px;
        margin: 0 calc((100vw - 1430px) / 2);
      }
      @media screen and (max-width: 600px) {
        min-width: 720px;
        margin: 0 calc((100vw - 720px) / 2);
      }
    }

    &__cards {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
      }

      &-item-1 {
        left: 50%;
        margin-left: -6px;
        bottom: 282px;
        @media screen and (max-width: 950px) {
          width: 332px;
          bottom: 213px;
        }
        @media screen and (max-width: 600px) {
          width: 168px;
          bottom: 111px;
        }
      }

      &-item-2 {
        left: 50%;
        margin-left: -421px;
        bottom: 78px;
        z-index: 3;
        @media screen and (max-width: 950px) {
          width: 307px;
          margin-left: -316px;
          bottom: 39px;
        }
        @media screen and (max-width: 600px) {
          width: 154px;
          margin-left: -160px;
          bottom: 21px;
        }
      }

      &-item-3 {
        left: 50%;
        margin-left: -150px;
        bottom: -172px;
        z-index: 2;
        @media screen and (max-width: 950px) {
          width: 332px;
          margin-left: -108px;
          bottom: -119px;
        }
        @media screen and (max-width: 600px) {
          width: 169px;
          margin-left: -58px;
          bottom: -58px;
        }
      }
    }
  }

  .idea-circular-text {
    position: relative;
    left: -6.2%;
    @media screen and (max-width: 950px) {
      width: 239px;
      left: -13.2%;
    }
    @media screen and (max-width: 600px) {
      width: 122px;
    }
  }

  .video {
    max-width: 1200px;
    width: 100%;

    margin: 0 auto;
    padding: 0 45px;
    @media screen and (max-width: 1200px) {
      padding: 0 45;
    }
    @media screen and (max-width: 600px) {
      padding: 0 20px;
    }

    ::v-deep .swiper-container {
      padding-bottom: 28px;
      @media screen and (max-width: 1200px) {
        overflow: visible;
      }
      @media screen and (max-width: 600px) {
        padding-bottom: 0;
      }

      .swiper-pagination-bullet {
        background: var(--decor-line-color);

        &.swiper-pagination-bullet-active {
          background: var(--menu-icon-color);
        }
      }
    }

    &::v-deep .swiper-slide {
      width: 445px;
      border-radius: 40px;
      overflow: hidden;

      @media screen and (max-width: 600px) {
        width: 235px;
        border-radius: 20px;
      }

      &:hover {
        @media screen and (min-width: 1023px) {
          opacity: 0.5;
          transition: all 0.3s;
          .video__play-btn:before {
            opacity: 1;
          }
        }
      }
    }

    ::v-deep .swiper-pagination {
      bottom: 0;
      color: #d4dadf;
      position: relative;
      margin-top: 32px;
      @media screen and (max-width: 600px) {
        margin-top: 20px;
      }
    }

    ::v-deep .swiper-pagination-current {
      font-family: "TimesNewRoman-I";
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      font-style: italic;
      color: var(--text-primary-color);
      letter-spacing: 0.04em;
    }

    ::v-deep .swiper-pagination-total {
      color: #d4dadf;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.04em;
    }

    .pagination__wrapper {
      position: relative;
      bottom: 72px;
      z-index: 1;
      @media screen and (max-width: 600px) {
        bottom: 45px;
      }

      button {
        transition: all 0.2s;

        &.left {
          transform: translateX(-22px);
        }

        &.right {
          transform: translateX(22px);
        }

        svg path {
          stroke: var(--text-primary-color);
        }

        @media screen and (min-width: 1023px) {
          &:hover {
            svg {
              path {
                stroke: #29f6d9;
              }
            }
          }
        }

        &:active {
          svg {
            path {
              stroke: #29f6d9;
            }
          }
        }
      }

      button.swiper-button-disabled {
        pointer-events: none;

        svg path {
          stroke: #d4dadf;
        }
      }
    }

    &__block {
      max-width: 70%;

      @media screen and (max-width: 960px) {
        max-width: 90%;
      }
      @media screen and (max-width: 580px) {
        max-width: 100%;
      }

      &-title {
        margin-bottom: 16px;
      }
    }

    &__video-wrapper {
      position: relative;

      video {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &__play-btn {
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      &:before {
        content: "Play";
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        opacity: 0;

        justify-content: center;
        align-items: center;
        background: #29f6d9;

        z-index: 2;

        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        margin: auto;

        color: #000000;

        transition: all 0.2s ease;

        font-family: "TimesNewRoman-I";
        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.04em;

        @media screen and (max-width: 425px) {
          width: 60px;
          height: 60px;
          font-size: 16px;
        }
      }

      &.active {
        @media screen and (max-width: 1023px) {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  .photo-bg {
    background: #fef3ed;
    overflow: hidden;
    padding: 0 45px;
    @media screen and (max-width: 768px) {
      padding: 0;
    }

    img {
      max-width: 100%;
      display: block;
      margin: 0 auto;
      @media screen and (max-width: 425px) {
        width: 100%;
      }
    }
  }

  .photo-bg_gray {
    background: #ecedf2;
    padding: 0 45px;
    @media screen and (max-width: 600px) {
      padding: 0 20px;
    }
  }

  .photo-bg_full {
    padding: 0;

    img {
      width: 100%;
      margin-bottom: -5px;
    }
  }

  .result {
    padding-bottom: 76px;
    @media screen and (max-width: 950px) {
      padding-bottom: 43px;
    }
    @media screen and (max-width: 600px) {
      padding-bottom: 23px;
    }

    .text-row {
      margin-bottom: 41px;
    }

    .text-row__right-text {
      margin-bottom: 7px;
      padding-right: 10px;
    }

    .text-row__right-text strong {
      font-weight: 600;
    }
  }

  .result-table {
    max-width: 963px;
    width: 100%;
    margin: 0 auto;
  }

  .table {
    display: table;
    width: 100%;
    text-align: left;
    @media screen and (max-width: 600px) {
      display: block;
    }

    &__row {
      display: table-row;
      @media screen and (max-width: 600px) {
        display: flex;
        flex-wrap: wrap;
      }

      &-title {
        .col {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.08em;
          padding-bottom: 32px;

          &:nth-child(1) {
            @media screen and (max-width: 600px) {
              display: none;
            }
          }
        }
      }
    }
  }

  .col {
    display: table-cell;
    vertical-align: top;
    font-weight: 400;
    font-size: 64px;
    line-height: 74px;
    color: var(--text-primary-color-case);
    padding-bottom: 35px;
    @media screen and (max-width: 950px) {
      font-size: 36px;
      line-height: 43px;
      padding-bottom: 23px;
    }

    span {
      display: block;
    }

    @media screen and (max-width: 600px) {
      padding-bottom: 26px;
    }

    &:nth-child(1) {
      width: 31.6%;

      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      min-width: 236px;
      padding-right: 15px;

      span {
        position: relative;
        padding-left: 13px;
        display: inline-block;
        @media screen and (max-width: 600px) {
          margin-left: 7px;
        }

        &::before {
          content: "";
          position: absolute;
          top: 9px;
          left: 0;
          width: 3px;
          height: 3px;
          background: var(--text-primary-color-case);
          border-radius: 50%;
        }
      }

      @media screen and (max-width: 600px) {
        width: 100%;
        font-size: 14px;
        line-height: 18px;
        padding-right: 0;
        padding-bottom: 14px;
      }
    }

    &:nth-child(2) {
      width: 39.4%;
      @media screen and (max-width: 600px) {
        width: 50%;
      }
    }

    &:nth-child(3) {
      width: 29.4%;
      @media screen and (max-width: 600px) {
        width: 50%;
      }
    }
  }

  .table-bottom {
    .table__row {
      &:nth-child(2),
      &:nth-child(3) {
        .col:nth-child(3),
        .col:nth-child(2) {
          font-size: 48px;
          line-height: 55px;
          @media screen and (max-width: 950px) {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }
    }
  }

  .table-devide {
    background: var(--case-allegra-color-top);
    height: 1px;
    margin: 14px -75px 48px;
    @media screen and (max-width: 950px) {
      margin: 14px -7px 28px;
    }
    @media screen and (max-width: 600px) {
      margin: 5px 0px 32px;
    }
  }

  .phone-stories {
    .banner-facebook__card {
      background: #404266;
      border: 0.8vw solid #404266;
      box-shadow: 0px 5.85526px 23.421px rgba(15, 28, 49, 0.08);
      border-radius: 42px;
      width: 15.4%;
      @media screen and (max-width: 768px) {
        width: 39%;
        border: 16px solid #404266;
      }
      @media screen and (max-width: 425px) {
        width: 65.4%;
        border-radius: 22px;
        border: 12px solid #404266;
      }

      img {
        @media screen and (max-width: 425px) {
          border-radius: 22px;
        }
      }
    }
  }
}
</style>
